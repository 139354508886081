import type { PaymentMethod } from "@commercelayer/sdk"
import type { OrderStatus } from "components/Card/MyOrder/FullOrder.props"

// N.B. ALL FUNCTIONS OR CONTIDIONS WITH THIS VARIABLE IS BECAUSE IT'S AN ARCHIVED ORDER FROM OLDER SITE
export const PRESTASHOP_IMPORT = "prestashop_import"

export type Shipment = {
  cost_amount_cents: number
  cost_amount_float: number
  created_at: string
  currency_code: string
  formatted_cost_amount: string
  get_rates_completed_at: null
  get_rates_errors: []
  get_rates_started_at: null
  id: string
  metadata: {
    shipment_update: string
  }
  number: string
  purchase_completed_at: string
  purchase_error_code: number
  purchase_error_message: string
  purchase_failed_at: string
  purchase_started_at: string
  rates: []
  reference: null
  parcels: {
    tracking_status: string | number
    tracking_status_updated_at: string | number
  }[]
  reference_origin: string
  selected_rate_id: number
  skus_count: number
  type: string
  updated_at: string
  status:
    | "draft"
    | "upcoming"
    | "cancelled"
    | "on_hold"
    | "picking"
    | "packing"
    | "ready_to_ship"
    | "shipped"
    | "delivered"
}

export const mapInfoDeliveryOrder = (shipment: Shipment) => {
  const status = shipment?.status
  const parcel = shipment?.parcels
    ? shipment?.parcels.find((parcel) => parcel.tracking_status)
    : null
  const { t } = useI18n()
  if (
    status === "draft" ||
    status === "upcoming" ||
    status === "on_hold" ||
    status === "picking" ||
    status === "packing" ||
    status === "ready_to_ship"
  ) {
    return t("shipmentsData.placed")
  } else if (status === "shipped") {
    if (parcel?.tracking_status === "out_for_delivery") {
      return t("shipmentsData.inDelivery")
    } else if (parcel?.tracking_status === "delivered") {
      return getFormatDate(parcel?.tracking_status_updated_at)
    } else {
      return t("shipmentsData.deliveredFromFactory")
    }
  } else if (status === "delivered") {
    return getFormatDate(
      parcel?.tracking_status_updated_at ?? shipment?.updated_at ?? new Date()
    )
  }
}

export const mapOrderStatus = (
  status: string,
  shipment: Shipment,
  origin: string
): OrderStatus | undefined => {
  const parcel =
    origin === PRESTASHOP_IMPORT
      ? null
      : shipment?.parcels
      ? shipment?.parcels.find((parcel) => parcel.tracking_status)
      : null
  switch (status) {
    case "placed":
      return "Editable"
    case "approved":
      let status = "PendingDelivery"
      if (shipment.status === "shipped" && origin !== PRESTASHOP_IMPORT) {
        if (parcel && parcel.tracking_status === "delayed") {
          status = "Delayed"
        } else if (parcel && parcel.tracking_status === "delivered") {
          status = "Delivered"
        } else {
          status = "PendingDelivery"
        }
      } else if (shipment.status === "delivered") {
        status = "Delivered"
      } else if (origin === PRESTASHOP_IMPORT) {
        status = "Delivered"
      } else {
        status = "PendingDelivery"
      }
      return status
    case "fullfilled":
      let statusFullfilled = "PendingDelivery"
      if (shipment.status === "shipped") {
        if (parcel?.tracking_status === "delayed") {
          statusFullfilled = "Delayed"
        } else if (parcel?.tracking_status === "delivered") {
          statusFullfilled = "Delivered"
        } else {
          statusFullfilled = "PendingDelivery"
        }
      } else if (shipment.status === "delivered") {
        status = "Delivered"
      }
      return statusFullfilled
    case "cancelled":
      return "Cancelled"
  }
}

export const mappingPaymentInfo = (
  paymentMethod: PaymentMethod | null,
  paymentSourceDetail: object | null
) => {
  // TODO: FINISH MAPPING ALL CASES
  if (paymentMethod) {
    switch (paymentMethod?.payment_source_type) {
      case "paypal_payments":
        return {
          icon: "PaymentPayPal",
          info: ""
        }
        break
      case "wire_transfers":
        if (paymentMethod.reference === "cash_on_delivery") {
          return {
            icon: "PaymentWallet",
            info: ""
          }
        } else {
          return {
            icon: "PaymentBonifico",
            info: ""
          }
        }
        break
      case "axerve_payments":
        const paymentMethodDetails =
          paymentSourceDetail?.payment_method_details?.paymentMethod

        const isApplePay = paymentMethodDetails
          ?.toLowerCase()
          ?.includes("apple")

        return {
          icon:
            paymentMethodDetails === "VISA"
              ? "PaymentVisa"
              : paymentMethodDetails === "MASTERCARD"
              ? "PaymentMastercard"
              : isApplePay
              ? "PaymentApplePay"
              : "PaymentGiftCard",
          info: `${paymentMethodDetails} **** **** **** ${
            paymentSourceDetail?.payment_method_details?.tokenDetails
              ?.CardDetails?.CardSuffix ?? "****"
          }`
        }
        break
      default:
        return {
          icon: "PaymentGiftCard",
          info: ""
        }
        break
    }
  } else {
    return {
      icon: "PaymentWallet",
      info: ""
    }
  }
}
